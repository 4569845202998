import React, { Fragment, useEffect } from 'react';
import moment from 'moment-timezone';
import {
  Avatar,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  TableSortLabel,
} from '@material-ui/core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
// import { MenuItem } from 'react-contextmenu';
// import { Search } from '@material-ui/icons';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
  // clearResults,

  setFromDetail,
  setPersonDataForRecords,
} from 'reducers/PersonSearchReducer';
import {
  setLineupPersonFiltersvalue,
  handleLineupChange,
  setAutoSearch,
} from 'reducers/LineupPersonSearchReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setLineupPhotos } from 'reducers/LineupsSearchReducer';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  tableRow: {
    cursor: 'pointer',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  root: {
    height: window.innerHeight - 422 + 'px',

    [theme.breakpoints.up('lg')]: {
      height: window.innerHeight - 422 + 'px',
    },
    [theme.breakpoints.only('lg')]: {
      height: window.innerHeight - 422 + 'px',
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    headCells,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const {
    inmatesViewOnly,
    inmatesViewSsn,
    employeesViewOnly,
    employeeViewSsn,
    employeeViewOnly,
    inmatesCreateLineups,
  } = useSelector((state) => state.Permissions);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id == 'p.ssn_nbr') {
            if (
              !(props.recordType == 'inmate' && inmatesViewSsn) &&
              !(props.recordType == 'employee' && employeeViewSsn)
            ) {
              return;
            }
          }

          return (
            <TableCell
              style={{
                width: `${
                  headCell.id == 'p.marks' || headCell.id == 'p.work_title'
                    ? '22%'
                    : headCell.id == 'p.lnam'
                    ? '18%'
                    : '13%'
                }`,
              }}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {props.recordType == 'inmate' && !inmatesViewOnly && inmatesCreateLineups && (
          <TableCell>Action</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function UserTable(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const recordType = useSelector((state) => state.personSearch.personForm.recordType);
  const lineupPhotos = useSelector((state) => state.LineupSearch.lineupPhotos);
  const {
    inmatesViewOnly,
    inmatesViewSsn,
    employeesViewOnly,
    employeeViewSsn,
    employeeViewOnly,
    inmatesCreateLineups,
    employeeCreateLineups,
  } = useSelector((state) => state.Permissions);

  const { persons } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [selected, setSelected] = React.useState([]);
  const { loading } = persons;
  const rowClick = (row) => {
    row.recordType = recordType;
    dispatch(setPersonDataForRecords(row));
    history.push(`/person/${recordType}/${row.ptspersonid}`);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const a = isAsc ? 'desc' : 'asc';

    props.getPersonsData('', '', true, { orderBy: property, direction: a });
    setOrder(a);
    setOrderBy(property);
  };

  useEffect(() => {
    if (persons.rows.length == 1 && !persons.fromDetails) {
      dispatch(setFromDetail(true));
      const row = persons.rows[0];
      row.recordType = recordType;
      dispatch(setPersonDataForRecords(row));
      history.push(`/person/${recordType}/${persons.rows[0].ptspersonid}`);
      // dispatch(clearResults());
    }
  }, [persons]);

  const handleClick = async (event, data) => {
    const image = data.Mugshots && JSON.parse(data.Mugshots);

    if (image.length > 0) {
      const defaultImage = image.filter(
        (img) => img.InternalCode == 'FRONT' && img.IsDefault == true
      );
      if (defaultImage.length > 0) {
        const object = {
          FullName:
            (data.lastname || '') + ', ' + (data.firstname || '') + ' ' + (data.middlename || ''),
          Born: data.born || '',
          Sex: data.sex || '',
          Ethnicity: data.ethnicity || '',
          SSNNumber: data.ssn || '',
          ptspersonid: data.ptspersonid || '',
          Height: data.height || '',
          Weight: data.weight || '',
          ptsImageID: defaultImage[0].ptsImageID || '',
          FileObject: defaultImage[0].FileObject || '',
          EyeColor: data.eyecolor || '',
          HairColor: data.haircolor || '',
        };

        const exist = lineupPhotos.filter((lm) => lm.ptsImageID == defaultImage[0].ptsImageID);
        if (exist.length == 0) {
          const newData = [...lineupPhotos, object];
          newData.forEach((item, index) => (item.OrderValue = index));
          dispatch(setLineupPhotos(newData));
        }
      }
    }
    const newForm = {};
    Object.entries(data).forEach(([key, val]) => {
      if (key == 'Race') {
        if (val) newForm['p.' + key] = {};
      } else if (key == 'eyes_cd' || key == 'hair_cd' || key == 'ethnic_cd' || key == 'sex_cd') {
        if (val) newForm['p.' + key] = {};
      }
    });

    Object.entries(data).forEach(([key, val]) => {
      if (key == 'eyes_cd' || key == 'hair_cd' || key == 'ethnic_cd' || key == 'sex_cd') {
        if (val) {
          newForm['p.' + key].search = val;
          newForm['p.' + key].type = 'select';
        }
      }
    });

    event.stopPropagation();
    dispatch(handleLineupChange(true));
    dispatch(setAutoSearch(true));
    dispatch(setLineupPersonFiltersvalue(newForm));
    // props.getPersonsData('', newForm);
    history.push('/lineup-search');
  };
  const headCells = [
    {
      id: `${recordType == 'inmate' ? 'p.docno' : 'p.emplmast_srl'}`,
      numeric: true,
      disablePadding: false,
      label: 'ID',
    },
    { id: 'p.lnam', numeric: false, disablePadding: false, label: 'NAME' },
    { id: 'p.dob_dtd', numeric: false, disablePadding: false, label: 'DOB' },
    { id: 'p.ssn_nbr', numeric: true, disablePadding: false, label: 'SSN' },
    { id: 'p.sex_cd', numeric: true, disablePadding: false, label: 'SEX' },
    {
      id: `${recordType == 'inmate' ? 'p.marks' : 'p.work_title'}`,
      numeric: true,
      disablePadding: false,
      label: `${recordType == 'inmate' ? 'Marks' : 'Work Title'}`,
    },
  ];
  // sconst p = persons?.rows[0] ? JSON.parse(persons?.rows[0]?.Mugshots) : '';
  const processPhoto = (photos, type) => {
    if (!photos) {
      return '';
    }
    const images = JSON.parse(photos);

    if (images.length < 1) {
      return '';
    }
    if (type == 'front') {
      const front = images.filter((img) => img.InternalCode == 'FRONT' && img.IsDefault == true);
      return front[0] ? `data:image/jpeg;base64,${front[0].FileObject}` : '';
    } else {
      const prof = images.filter((img) => img.InternalCode == 'PROFILE' && img.IsDefault == true);
      return prof[0] ? `data:image/jpeg;base64,${prof[0].FileObject}` : '';
    }
  };
  return (
    <Fragment>
      <div className={'table-responsive ' + classes.root}>
        <Table className={classes.table}>
          <EnhancedTableHead
            classes={classes}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            recordType={recordType}
          />
          <TableBody>
            {persons?.rows?.length > 0 && !loading ? (
              persons.rows.map((p, i) => {
                let address = '';
                // if (p.Addresses !== null) {
                //   const _address = JSON.parse(p.Addresses);
                //   address = _address[0].FullAddressText;
                // }
                // let mugshot = null;
                // if (p.Mugshots && p.Mugshots != '') {
                //   const _mugshot = JSON.parse(p.Mugshots);
                //   mugshot =;
                // }

                return (
                  <TableRow key={i} onClick={() => rowClick(p)}>
                    <TableCell className="font-weight-bold">{p.PersonID}</TableCell>
                    <TableCell>
                      <div className="d-flex align-items-center">
                        <Avatar
                          className={clsx('mr-2', classes.large)}
                          variant="rounded"
                          alt={p.ptspersonid}
                          src={processPhoto(p.Mugshots, 'front')}
                        />
                        <Avatar
                          className={clsx('mr-2', classes.large)}
                          variant="rounded"
                          alt={p.ptspersonid}
                          src={processPhoto(p.Mugshots, 'prof')}
                        />
                        <div>
                          <Typography>
                            {recordType === 'inmate'
                              ? `${p.lastname || ''}${p.lastname && ', '}${p.firstname || ''}`
                              : `${p.lastname || ''}${p.lastname && ', '}${p.firstname || ''} ${
                                  p.middlename || ''
                                }`}
                          </Typography>

                          <span className="text-black-50 d-block">{address}</span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{p.born ? moment(p.born).format('MM/DD/YYYY') : ''}</TableCell>
                    {(recordType == 'inmate' && !inmatesViewOnly && inmatesViewSsn) ||
                    (recordType == 'employee' && !employeesViewOnly && employeeViewSsn) ? (
                      <TableCell className="">{p.ssn}</TableCell>
                    ) : (
                      <TableCell className=""></TableCell>
                    )}
                    <TableCell className="">{p.sex}</TableCell>
                    <TableCell>{p.worktitle}</TableCell>
                    {recordType == 'inmate' && !inmatesViewOnly && inmatesCreateLineups && (
                      <TableCell className="text-left">
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, p)}>
                          <PageviewIcon color="primary" fontSize="large" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : loading ? (
              <CircularProgress
                color="primary"
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                }}
                className={classes.buttonProgress}
              />
            ) : (
              <p
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                }}>
                No Rows
              </p>
            )}
          </TableBody>
        </Table>
        {/* <table className="table table-hover text-nowrap mb-0">
          <thead>
            <tr></tr>
          </thead>
          <tbody> */}

        {/* </tbody>
        </table> */}
      </div>
    </Fragment>
  );
}

export default UserTable;
